
p {
  color: rgb(118, 120, 123);
  font-weight: 300; 
}

:root h1 
{
  color: rgb(118, 120, 123);
  font-weight: 300;
  letter-spacing: -0.025em;
}

* 
{
  font-family: Roboto, sans-serif;
}

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }
  a:hover {
    text-decoration: none !important; }

.content {
  padding: 7rem 0; }

h2 {
  font-size: 20px; 
}

.half, .half .container > .row {
  height: 100vh;
  min-height: 700px; }

@media (max-width: 991.98px) {
  .half .bg {
    height: 200px; } }

.half .contents {
  background: #f8f8f8; }

.half .contents, .half .bg {
  width: 50%; }
  @media (max-width: 1199.98px) {
    .half .contents, .half .bg {
      width: 100%; } }
  .half .contents .form-control, .half .bg .form-control {
    border: none;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: 54px;
    background: #fff; }
    .half .contents .form-control:active, .half .contents .form-control:focus, .half .bg .form-control:active, .half .bg .form-control:focus {
      outline: none;
      -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1); }

.half .bg {
  background-size: cover;
  background-position: center; }

.half a {
  color: #888;
  text-decoration: underline; }

.half .btn {
  height: 54px;
  padding-left: 30px;
  padding-right: 30px; }



.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px; }
  .control .caption {
    position: relative;
    top: .2rem;
    color: #d8d8d8; }

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0; }


:root .btn-login,
:root .btn-login:hover,
:root .btn-login:active,
:root .btn-login:focus
 {
  color: #fff;
  background-color: #02816a;
  border-color: #02816a;
  font-weight: bold;
  border-radius: 0.5em;
}

:root .btn-login:active
{
  opacity: 0.75;
  outline-color: #02816a;
}

:root label 
{
  margin: 8px 0;
  color: #aaa;
}

.logo-matt 
{
  display: inline-block;
  height: 1.5em;
  position: relative;
  margin-top: -0.26em;
  margin-left: -0.36em;
}

.subtitle-matt
{
  font-size: inherit;
  color: rgb(118, 120, 123);
}

.form-group.first
{
  margin-bottom: 14px;
}

@media (max-width: 991px) 
{
  :root .bg 
  {
    background-position: top;
  }
  
}

:root .form-err .form-group input,
:root .form-err .form-group input:focus
{
  outline: 3px solid rgba( 255, 50, 0, 0.2 );
}
